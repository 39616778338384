// @ts-ignore
import { useUserContext } from '../context/UserContext.tsx';
import { useEffect, useRef, useState } from 'react'
import {
  Fab,
  Box,
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Typography,
  Rating,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import Iconify from '../components/iconify/Iconify';
import { useSnackbar } from '../components/snackbar';
import * as apis from '../utils/apirequests.js'
import useCheckMobileScreen from '../hooks/useCheckMobileScreen';

export default function Feedback() {
  const userUuid = useRef<string | null>(null)
  const sessionInfo = useRef({
    token: null,
    expiresAt: 0,
  })
  const isMobile = useCheckMobileScreen()

  const { user, session, page } = useUserContext()
  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState(null);
  const [anonymous, setAnonymous] = useState(false)
  const [feedback, setFeedback] = useState("")

  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onRatingChange = (event, value) => {
    setRating(value)
  }

  const setPostAnonymously = (event, value) => {
    setAnonymous(value)
  }

  const onFeedbackChange = (event) => {
    setFeedback(event.target.value)
  }

  const handlePost = (userRating, feedbackContent) => {
    if (!anonymous) {
      authedBackendRequest("post_feedback", { "rating": userRating, "feedback_content": feedbackContent })
    } else {
      apis.backendRequest("post_anonymous_feedback", { "rating": userRating, "feedback_content": feedbackContent })
    }
    enqueueSnackbar('Thanks for your feedback!', { variant: 'success' });
    setRating(null)
    setAnonymous(false)
    setFeedback("")
    handleClose()
  }

  async function authedBackendRequest(url = '', data = {}) {
    if (!sessionInfo.current.token) {
      return null
    }
    data['user_uuid'] = userUuid.current
    data['token'] = sessionInfo.current.token
    return apis.backendRequest(url, data)
  }

  // Whenever local user state finds a new userUuid, get a valid token for the user
  useEffect(() => {
    if (user) {
      userUuid.current = user.userUuid
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  // Whenever global session updates, update the local ref
  useEffect(() => {
    sessionInfo.current = session
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session])

  return (
    <>
      {!isMobile && page !== "Home" &&
        <Box sx={{
          padding: "4px",
          right: "24px",
          bottom: "24px",
          zIndex: "79",
          position: "fixed",
          borderRadius: "50%",
          backdropFilter: "blur(6px)",
        }}>
          <Fab color="primary" variant="softExtended" onClick={handleClickOpen} >
            <Iconify icon="ic:outline-feedback" width={24} />
            Feedback
          </Fab>
        </Box>
      }

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{
          maxWidth: "600px"
        }}>We love your feedback!</DialogTitle>
        <DialogContent>
          <Stack flexDirection="row" alignItems="center" display="flex" sx={{
            gap: "12px",
            marginBottom: "12px"
          }}>
            <Typography>How do you like Jomo?</Typography>
            <Rating name="rating" value={rating} onChange={onRatingChange} />
          </Stack>
          <TextField
            autoFocus
            fullWidth
            multiline
            rows={3}
            margin="dense"
            variant="outlined"
            label="Feedback details"
            onChange={onFeedbackChange}
          />
        </DialogContent>
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
          <FormControlLabel label="Post anonymously" control={<Checkbox checked={anonymous} onChange={setPostAnonymously} />} sx={{
            marginLeft: "12px",
          }} />
          <DialogActions>
            <Button onClick={handleClose} color="inherit">
              Cancel
            </Button>
            <Button onClick={() => handlePost(rating, feedback)} variant="contained">
              Post feedback
            </Button>
          </DialogActions>
        </Stack>
      </Dialog>
    </>
  );
}