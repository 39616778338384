import { useEffect } from 'react'
// @ts-ignore
import { useUserContext } from '../context/UserContext.tsx';
import { Box, Grid, Stack, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

function Home() {
  const { setPage } = useUserContext()

  useEffect(() => {
    setPage("Home")
  }, [setPage])

  const handleStartMint = (url) => {
    window.open(url, "_self");
  }

  return (
    <Box sx={{ p: '50px' }}>
      {/* <Alert severity="info">
        Thanks for using Jomo. We use cryptographic technologies to always keep your data secure and private while you claim the attestations. <br />
        Follow us on <a href="https://twitter.com/0xJomoID">Twitter</a> to keep updated.
      </Alert> */}

      <Typography variant="h4" textAlign="left" sx={{ maxWidth: "800px", my: '20px', px: '20px' }}>
        Active Mints
      </Typography>

      <Grid container spacing={1} columns={{ xs: 4, sm: 6, md: 6 }}>
        <Grid>
          <Card sx={{ maxWidth: 600, p: '20px', mx: '20px', my: '20px' }}>
            <CardContent>
              <Stack direction="row" spacing={2} alignItems='center'>
                {/* <Avatar alt="Robinhood" src={robinhoodImg} /> */}
                <Typography gutterBottom variant="h5" component="div">
                  a16z Crypto Startup School Rejects
                </Typography>
              </Stack>
              <Typography variant="body2" color="text.secondary" sx={{ my: '20px' }}>
                Mint an SBT by proving you were rejected by a16z Crypto Startup School 2024, and join a vibrant community of rejects alike.
              </Typography>
            </CardContent>
            <CardActions sx={{ px: '20px' }}>
              <Button variant="contained" size="small" onClick={() => handleStartMint("./a16zcssreject")}>Mint</Button>
              {/* <Button variant="outlined" size="small">Share</Button> */}
            </CardActions>
          </Card>
        </Grid>

        {/* <Grid>
          <Card sx={{ maxWidth: 600, p: '20px', mx: '20px', my: '20px' }}>
            <CardContent>
              <Stack direction="row" spacing={2} alignItems='center'>
                <Avatar alt="Robinhood" src={robinhoodImg} />
                <Typography gutterBottom variant="h5" component="div">
                  Robinhood - Investment ROI
                </Typography>
              </Stack>
              <Typography variant="body2" color="text.secondary" sx={{ my: '20px' }}>
                Verify investment return on a yearly / monthly / weekly / daily basis.
              </Typography>
            </CardContent>
            <CardActions sx={{ px: '20px' }}>
              <Button variant="contained" size="small">Start Verify</Button>
              <Button variant="outlined" size="small">Share</Button>
            </CardActions>
          </Card>
        </Grid> */}
      </Grid>

    </Box>
  )
}

export default Home