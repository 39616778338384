import { useEffect, useState, useRef, useCallback } from 'react'
// @ts-ignore
import { useUserContext } from '../context/UserContext.tsx';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import {
  Button, Stack, Typography, Box, Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import cssRejectImg from '../images/css_rejects.png'
import telegramImg from '../images/telegram.png'
import icebreakerImg from '../images/icebreaker.png'
import emaildownloadhelpImg from '../images/emaildownloadhelp.png'
import celebrateImg from '../images/celebrate.png'
import Iconify from '../components/iconify';
import Label from '../components/label';
import SvgColor from '../components/svg-color';
import { UploadBox } from '../components/upload';
import * as apis from '../utils/apirequests.js'
import {
  useConnectModal,
} from '@rainbow-me/rainbowkit';
import PostalMime, { Email } from 'postal-mime';
import { ethers } from 'ethers';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  margin: 4,
  width: "128px",
  height: "128px",
}));

const contractAddress = "0xd36251293637CaE765A9CBDDAAb9Ae4aDBEA0173"
const sbtType = 1
const abi = [
  {
    "inputs": [
      {
        "internalType": "string",
        "name": "title",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "description",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "imageURL",
        "type": "string"
      }
    ],
    "name": "addSBTType",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "approve",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string",
        "name": "name",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "symbol",
        "type": "string"
      },
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "sender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      }
    ],
    "name": "ERC721IncorrectOwner",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "operator",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "ERC721InsufficientApproval",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "approver",
        "type": "address"
      }
    ],
    "name": "ERC721InvalidApprover",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "operator",
        "type": "address"
      }
    ],
    "name": "ERC721InvalidOperator",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      }
    ],
    "name": "ERC721InvalidOwner",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "receiver",
        "type": "address"
      }
    ],
    "name": "ERC721InvalidReceiver",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "sender",
        "type": "address"
      }
    ],
    "name": "ERC721InvalidSender",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "ERC721NonexistentToken",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      }
    ],
    "name": "OwnableInvalidOwner",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "OwnableUnauthorizedAccount",
    "type": "error"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "approved",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "Approval",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "operator",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "bool",
        "name": "approved",
        "type": "bool"
      }
    ],
    "name": "ApprovalForAll",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "_fromTokenId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "_toTokenId",
        "type": "uint256"
      }
    ],
    "name": "BatchMetadataUpdate",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "_tokenId",
        "type": "uint256"
      }
    ],
    "name": "MetadataUpdate",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "typeId",
        "type": "uint256"
      }
    ],
    "name": "mintSBT",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "safeTransferFrom",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "internalType": "bytes",
        "name": "data",
        "type": "bytes"
      }
    ],
    "name": "safeTransferFrom",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "typeId",
        "type": "uint256"
      }
    ],
    "name": "SBTMinted",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "typeId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "string",
        "name": "title",
        "type": "string"
      }
    ],
    "name": "SBTTypeAdded",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "operator",
        "type": "address"
      },
      {
        "internalType": "bool",
        "name": "approved",
        "type": "bool"
      }
    ],
    "name": "setApprovalForAll",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "Transfer",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "transferFrom",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      }
    ],
    "name": "balanceOf",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "holder",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "typeId",
        "type": "uint256"
      }
    ],
    "name": "balanceOfOnType",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "getApproved",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "typeId",
        "type": "uint256"
      }
    ],
    "name": "getSBTType",
    "outputs": [
      {
        "components": [
          {
            "internalType": "string",
            "name": "title",
            "type": "string"
          },
          {
            "internalType": "string",
            "name": "description",
            "type": "string"
          },
          {
            "internalType": "string",
            "name": "imageURL",
            "type": "string"
          }
        ],
        "internalType": "struct SoulboundToken.SBTType",
        "name": "",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "operator",
        "type": "address"
      }
    ],
    "name": "isApprovedForAll",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "name",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "ownerOf",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes4",
        "name": "interfaceId",
        "type": "bytes4"
      }
    ],
    "name": "supportsInterface",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "symbol",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "tokenURI",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "typeId",
        "type": "uint256"
      }
    ],
    "name": "totalBalanceOnType",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  }
]

const Campaign = () => {

  const { session, setPage } = useUserContext()
  const walletAddress = useRef(null)
  const { openConnectModal } = useConnectModal();

  const [mouseOn, setMouseOn] = useState(0)
  const [mintState, setMintState] = useState(0)

  const fileBlob = useRef(null);
  const [fileName, setFileName] = useState(null)
  const [invalidMail, setInvalidMail] = useState<Boolean | null>(null)

  const [minting, setMinting] = useState(false)
  const [isHolder, setHolder] = useState(false)
  const [totalMinted, setTotalMinted] = useState(0)

  const firstMount = useRef(true)

  const parseEmail = async function (email): Promise<Email> {
    const parser = new PostalMime();
    const mail = await parser.parse(await email.arrayBuffer())
    return mail
  }

  const verifyEmailFile = async function (emailFile) {
    const mail = await parseEmail(emailFile)
    if (!mail.from.address.endsWith("@a16z.com")) {
      setInvalidMail(true)
      return
    }
    if (!mail.subject.startsWith("a16z Crypto Startup School Interview:")) {
      setInvalidMail(true)
      return
    }
    setInvalidMail(false)
  }

  const handleDropFile = useCallback(async (acceptedFiles: File[]) => {
    const newFile = acceptedFiles[0];
    if (newFile) {
      fileBlob.current = newFile;
      setFileName(newFile.name)
      verifyEmailFile(newFile)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mintSBT = async function () {
    setMinting(true)
    const res = await apis.backendFileRequest("mint_sbt", {
      sbt_type: "a16zcssrejects",
      email: fileBlob.current,
      wallet_address: walletAddress.current,
    })
    setMinting(false)
    if (res.valid_email) {
      setMintState(5)
      setHolder(true)
    } else {
      setMintState(3)
      fileBlob.current = null
      setFileName(null)
    }
  }

  const redirectToCommunity = async function () {
    window.open("https://telegram.me/collablandbot?start=VFBDI1RFTCNDT01NIy0xMDAyMDI1NTAxMTk4", "_blank")
  }

  const handleMouseEnter = async function (index) {
    setMouseOn(index)
  }

  const handleMouseLeave = async function (_) {
    setMouseOn(0)
  }

  const startMint = async function () {
    setMintState(1)
    if (walletAddress.current === null) {
      openConnectModal()
    }
  }

  const queryUserHolding = async function () {
    let provider = ethers.getDefaultProvider("sepolia");
    let contract = new ethers.Contract(contractAddress, abi, provider)

    if (walletAddress.current) {
      let balance = parseInt(await contract.balanceOfOnType(walletAddress.current, sbtType))
      if (balance > 0) {
        setHolder(true)
      } else {
        setHolder(false)
      }
    }

    let totalBalance = parseInt(await contract.totalBalanceOnType(sbtType))
    setTotalMinted(totalBalance)
  }

  useEffect(() => {
    if (session !== null && session.address !== null) {
      walletAddress.current = session.address
      queryUserHolding()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session])

  useEffect(() => {
    setPage("Campaign")
    if (firstMount.current) {
      firstMount.current = false
      queryUserHolding()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Stack alignItems={"center"} my={5} gap={5}>
        <Stack gap={2}>
          <Box>
            <Typography variant='h4'>a16z Crypto Startup School 2024 Rejects</Typography>
          </Box>
          <Stack direction={"row"} maxWidth={"800px"} justifyContent={"space-between"} gap={8}>
            <Stack minWidth={0.4} gap={2} justifyContent={"center"}>
              <Stack direction={"row"} gap={0.5} alignItems={"center"}>
                <Iconify icon="lets-icons:check-fill" width={20} color={"primary.main"} />
                <Typography variant='caption'>Verified by</Typography>
                <Label color='warning' variant='soft'>Proof of a16z CSS 2024 rejection email</Label>
              </Stack>
              <Stack gap={2}>
                <Typography variant='body2'>
                  This soul bound token represents community of founders building the latest web3 startups and not afraid of rejection letters.
                </Typography>
                <Typography variant='body2'>
                  Join the rejects - where setbacks are just pit stops on our road to greatness! Let's hang out, share war stories, and turn those rejection vibes into success fuel. Virtual hangouts, chill workshops, and a sprinkle of rejection humor - we're rewriting the startup script, one rejection at a time! 🚀
                </Typography>
              </Stack>
              {!isHolder && <Button variant='contained' sx={{ "width": 1, "mt": 2 }} onClick={startMint}>✨ Free Mint</Button>}
              {isHolder && <Typography textAlign={"center"}>You have successfully minted this SBT.</Typography>}
              {totalMinted > 0 && <Typography variant='caption' textAlign={"center"}>{totalMinted} minted. Since Nov. 2023</Typography>}
            </Stack>

            <Stack minWidth={0.4} alignItems={"center"} gap={2} mt={4}>
              <img src={cssRejectImg} alt="a16z CSS reject"></img>
            </Stack>
          </Stack>
        </Stack>

        <Stack width={1} maxWidth={"800px"} gap={2}>
          <Stack>
            <Typography variant='h5'>Unlocks</Typography>
          </Stack>
          <Grid container item columnSpacing={4} rowSpacing={4}>
            <Grid item>
              <Box onMouseOver={() => { handleMouseEnter(1) }} onMouseLeave={() => { handleMouseLeave(1) }}>
                <Button sx={{ m: 0, padding: 0 }} disabled={mouseOn === 1 && !isHolder} onClick={redirectToCommunity}>
                  {mouseOn === 1 && !isHolder &&
                    <Box position={"absolute"} top={0} left={0} width={1} height={1} sx={{
                      background: "rgba(120, 120, 120, 0.8)",
                      border: "4px solid",
                      borderColor: "white",
                      borderRadius: "12px",
                      zIndex: 299,
                      backdropFilter: "blur(5px)",
                    }}>
                      <Stack justifyContent={"center"} alignItems={"center"} height={1}>
                        <Iconify icon="ic:baseline-lock" width={20} color={"white"} />
                        <Typography variant='subtitle2' color="white">Mint to Unlock</Typography>
                      </Stack>
                    </Box>
                  }
                  <Item sx={{ backgroundColor: "#29A7E9", color: "white" }}>
                    <Stack justifyContent={"center"} height={1}>
                      <Typography variant="h5" textAlign={"left"} fontWeight={"light"}>
                        Join
                      </Typography>
                      <Typography variant="h5" textAlign={"left"} fontWeight={"light"}>
                        Rejects
                      </Typography>
                      <Stack direction={"row"} alignItems={"center"} gap={2}>
                        <Typography variant="h5" textAlign={"left"} fontWeight={"light"}>
                          Fam
                        </Typography>
                        <SvgColor src={telegramImg} />
                      </Stack>
                    </Stack>
                  </Item>
                </Button>
              </Box>
            </Grid>
            <Grid item>
              <Box onMouseOver={() => { handleMouseEnter(2) }} onMouseLeave={() => { handleMouseLeave(2) }}>
                <Button sx={{ m: 0, padding: 0 }} disabled={mouseOn === 2}>
                  {mouseOn === 2 &&
                    <Box position={"absolute"} top={0} left={0} width={1} height={1} sx={{
                      background: "rgba(120, 120, 120, 0.8)",
                      border: "4px solid",
                      borderColor: "white",
                      borderRadius: "12px",
                      zIndex: 299,
                      backdropFilter: "blur(5px)",
                    }}>
                      <Stack justifyContent={"center"} alignItems={"center"} height={1}>
                        <Iconify icon="ic:baseline-lock" width={20} color={"white"} />
                        {!isHolder && <Typography variant='subtitle2' color="white">Mint to Unlock</Typography>}
                        {isHolder && <Typography variant='subtitle2' color="white">Coming Soon</Typography>}
                      </Stack>
                    </Box>
                  }
                  <Item sx={{ backgroundColor: "black", color: "white" }}>
                    <Stack justifyContent={"center"} height={1}>
                      <Typography variant="h5" textAlign={"left"} fontWeight={"light"}>
                        Showcase
                      </Typography>
                      <Stack direction={"row"} alignItems={"center"} gap={2}>
                        <Typography variant="h5" textAlign={"left"} fontWeight={"light"}>
                          On
                        </Typography>
                        <SvgColor src={icebreakerImg} />
                      </Stack>
                      <Typography variant="h5" textAlign={"left"} fontWeight={"light"}>
                        Icebreaker
                      </Typography>
                    </Stack>
                  </Item>
                </Button>
              </Box>
            </Grid>
            <Grid item>
              <Box onMouseOver={() => { handleMouseEnter(3) }} onMouseLeave={() => { handleMouseLeave(3) }}>
                <Button sx={{ m: 0, padding: 0 }} disabled={mouseOn === 3}>
                  {mouseOn === 3 &&
                    <Box position={"absolute"} top={0} left={0} width={1} height={1} sx={{
                      background: "rgba(120, 120, 120, 0.8)",
                      border: "4px solid",
                      borderColor: "white",
                      borderRadius: "12px",
                      zIndex: 299,
                      backdropFilter: "blur(5px)",
                    }}>
                      <Stack justifyContent={"center"} alignItems={"center"} height={1}>
                        <Iconify icon="ic:baseline-lock" width={20} color={"white"} />
                        {!isHolder && <Typography variant='subtitle2' color="white">Mint to Unlock</Typography>}
                        {isHolder && <Typography variant='subtitle2' color="white">Coming Soon</Typography>}
                      </Stack>
                    </Box>
                  }
                  <Item sx={{ backgroundColor: "#E9C47C", color: "black" }}>
                    <Stack justifyContent={"center"} height={1}>
                      <Typography variant="h5" textAlign={"left"} fontWeight={"light"}>
                        Build
                      </Typography>
                      <Typography variant="h5" textAlign={"left"} fontWeight={"light"}>
                        Your
                      </Typography>
                      <Stack direction={"row"} alignItems={"center"} gap={2}>
                        <Typography variant="h5" textAlign={"left"} fontWeight={"light"}>
                          Unlock ✨
                        </Typography>
                      </Stack>
                    </Stack>
                  </Item>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Stack>
      </Stack>

      <Dialog
        open={session && session.address && mintState > 0} onClose={() => { setMintState(0) }}
      >
        {mintState === 1 &&
          <Box sx={{ width: "450px", padding: "10px" }}>
            <DialogTitle variant='h5'>Verify as a Reject</DialogTitle>
            <DialogContent>
              <Stack alignItems="center" display="flex" sx={{
                gap: "12px",
                marginBottom: "12px"
              }}>
                <Typography>Prove that you are a Reject to unlock authentic communities, social status and more.</Typography>
                <Typography>Download then upload your rejection email from a16z CSS to prove and mint an SBT containing verified user data.</Typography>
              </Stack>
            </DialogContent>
            <Stack flexDirection="row" alignItems="center" justifyContent="center" width={1}>
              <DialogActions>
                <Button sx={{ width: "200px" }} onClick={() => { setMintState(2) }} variant="contained">
                  Continue
                </Button>
              </DialogActions>
            </Stack>
          </Box>
        }
        {mintState === 2 &&
          <Box sx={{ width: "450px", padding: "10px" }}>
            <DialogTitle variant='h5'>1. Download .eml</DialogTitle>
            <DialogContent>
              <Stack alignItems="center" display="flex" sx={{
                gap: "16px",
                marginBottom: "12px"
              }}>
                <Typography>Find your rejection email on <b><i>Desktop</i></b> and click on the ellipsis menu in the top right corner to "Download message". For help visit here.</Typography>
                <img src={emaildownloadhelpImg} alt="Email Download Example"></img>
              </Stack>
            </DialogContent>
            <Stack flexDirection="row" alignItems="center" justifyContent="center" width={1}>
              <DialogActions>
                <Button sx={{ width: "200px" }} onClick={() => { setMintState(3) }} variant="contained">
                  Continue
                </Button>
              </DialogActions>
            </Stack>
          </Box>
        }
        {mintState === 3 &&
          <Box sx={{ width: "450px", padding: "10px" }}>
            <DialogTitle variant='h5'>2. Upload .eml</DialogTitle>
            <DialogContent>
              <Stack alignItems="center" display="flex" sx={{
                gap: "16px",
              }}>
                <Typography>Upload your a16 rejection email to prove your authenticity. For more detailed instructions, visit here.</Typography>
                <UploadBox
                  onDrop={handleDropFile}
                  placeholder={
                    <Stack spacing={0.5} alignItems="center">
                      <Iconify icon="eva:cloud-upload-fill" width={40} />
                      {!fileName && <Typography variant="body2">Upload file</Typography>}
                      {fileName && <Typography variant="body2">{fileName}</Typography>}
                    </Stack>
                  }
                  sx={{ width: 1, height: 'auto', py: 2.5 }}
                />
              </Stack>
            </DialogContent>
            <Stack alignItems="center" justifyContent="center" width={1}>
              {invalidMail === true &&
                <Typography variant="caption" color="error">Uploaded email didn't pass the requirements.</Typography>
              }
              {invalidMail === false &&
                <Typography variant="caption" color="info">Email uploaded and parsed.</Typography>
              }
              <DialogActions>
                <Button
                  disabled={invalidMail === null || invalidMail === true || !fileName}
                  sx={{ width: "200px" }} onClick={() => { setMintState(4) }} variant="contained"
                >
                  Continue
                </Button>
              </DialogActions>
            </Stack>
          </Box>
        }
        {mintState === 4 &&
          <Box sx={{ width: "450px", padding: "10px" }}>
            <DialogTitle variant='h5'>3. mint</DialogTitle>
            <DialogContent>
              <Stack alignItems="center" display="flex" sx={{
                gap: "16px",
              }}>
                <Typography>Mint your verification as an SBT to join the Rejects Fam community.</Typography>
                <img src={cssRejectImg} width="200px" alt="CSS Rejects Fam"></img>
              </Stack>
            </DialogContent>
            <Stack flexDirection="row" alignItems="center" justifyContent="center" width={1}>
              <DialogActions>
                {!minting &&
                  <Button sx={{ width: "200px" }} onClick={() => { mintSBT() }} variant="contained">
                    Mint
                  </Button>
                }
                {minting &&
                  <Button sx={{ width: "200px" }} variant="contained" disabled>
                    Minting
                  </Button>
                }
              </DialogActions>
            </Stack>
          </Box>
        }
        {mintState === 5 &&
          <Box sx={{ width: "450px", padding: "10px" }}>
            <DialogTitle variant='h5'>Success!</DialogTitle>
            <DialogContent>
              <Stack alignItems="center" display="flex" sx={{
                gap: "48px",
                marginBottom: "32px"
              }}>
                <Typography width={1}>You won't have to worry about the fomo now :)</Typography>
                <img src={celebrateImg} width="50px" alt="CSS Rejects Fam"></img>
              </Stack>
            </DialogContent>
            <Stack flexDirection="row" alignItems="center" justifyContent="center" width={1}>
              <DialogActions>
                <Button sx={{ width: "200px" }} onClick={() => { redirectToCommunity() }} variant="contained">
                  Join Community
                </Button>
              </DialogActions>
            </Stack>
          </Box>
        }
      </Dialog >
    </>
  );
};

export default Campaign;