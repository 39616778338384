import { ConnectButton } from '@rainbow-me/rainbowkit';

function User() {
    return (
        <ConnectButton
            showBalance={false}
        ></ConnectButton>
    )
}

export default User