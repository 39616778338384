import './App.css';
import { BrowserRouter } from "react-router-dom"
import { UserContext } from './context/UserContext.tsx';
import ThemeProvider from './theme';
import { useState } from "react";
import SnackbarProvider from './components/snackbar';
import { MotionLazyContainer } from './components/animate';
import RainbowedApp from './pages/RainbowedApp';

function App() {
  const [page, setPage] = useState(null);
  const [user, setUser] = useState(null);
  const [session, setSession] = useState(null);

  return (
    <BrowserRouter>
      <MotionLazyContainer>
        <ThemeProvider>
          <SnackbarProvider>
            <UserContext.Provider value={{ page, setPage, user, setUser, session, setSession }}>
              <RainbowedApp />
            </UserContext.Provider>
          </SnackbarProvider>
        </ThemeProvider >
      </MotionLazyContainer>
    </BrowserRouter>
  );

}

export default App;
