import { useEffect, useState, useRef } from 'react'
// @ts-ignore
import { useUserContext } from '../context/UserContext.tsx';
import Campaign from './Campaign';
import NavBar from './NavBar';
import { Route, Routes } from "react-router-dom"
import Feedback from './Feedback';
import Page404 from './Page404';
import * as apis from '../utils/apirequests.js'

import '@rainbow-me/rainbowkit/styles.css';
import {
  lightTheme,
  AuthenticationStatus,
  createAuthenticationAdapter,
  RainbowKitAuthenticationProvider,
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { SiweMessage } from 'siwe';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import {
  mainnet,
  // polygon,
  // optimism,
  // arbitrum,
  // base,
  // zora,
} from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import Home from './Home';

function RainbowedApp() {
  const { page, setSession } = useUserContext()

  const sessionInfo = useRef(null)
  const [authStatus, setAuthStatus] = useState<AuthenticationStatus>("loading")

  const firstMount = useRef(true)

  const logoutSession = async () => {
    sessionInfo.current = null
    setSession(null)
    await localStorage.removeItem("walletSigninSessionData")
  }

  const verifySession = async (sessionData) => {
    if (sessionData.expiresAt < Date.now()) {
      await logoutSession()
    }

    const jsonData = await apis.backendRequest("refresh_wallet_signin", {
      "wallet_address": sessionData.address,
      "session_token": sessionData.token,
    });

    // Calculate wallet merkle root with local wallets information and compare
    if (jsonData && jsonData.ok) {
      const newSession = {
        address: jsonData.address,
        token: jsonData.session.token,
        expiresAt: jsonData.session.expires_at,
      }
      sessionInfo.current = newSession
      setSession(sessionInfo.current)
      localStorage.setItem("walletSigninSessionData", JSON.stringify(newSession))
    } else {
      await logoutSession()
    }
  };

  const trySigninOrRefreshToken = async () => {
    const sessionData: any = JSON.parse(localStorage.getItem("walletSigninSessionData"))
    if (sessionData) {
      sessionInfo.current = sessionData
      await verifySession(sessionData)
    }

    if (sessionInfo.current) {
      setAuthStatus("authenticated")
    } else {
      setAuthStatus("unauthenticated")
    }
  }

  let navbar = null
  if (page && !["404"].includes(page)) {
    navbar = <NavBar />
  }

  useEffect(() => {
    if (firstMount.current) {
      firstMount.current = false
      trySigninOrRefreshToken()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Rainbow Configs
  const { chains, publicClient } = configureChains(
    [mainnet],
    [
      publicProvider()
    ]
  );
  const { connectors } = getDefaultWallets({
    appName: 'Jomo',
    projectId: '68356ccffc405fa76490532f6ad56492',
    chains
  });
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient
  })
  const authenticationAdapter = createAuthenticationAdapter({
    getNonce: async () => {
      const jsonData = await apis.backendRequest("get_wallet_signin_nonce", {});

      return jsonData.nonce
    },

    createMessage: ({ nonce, address, chainId }) => {
      const message = new SiweMessage({
        domain: window.location.host,
        address: address,
        statement: 'Sign in with Ethereum to Jomo.',
        uri: window.location.origin,
        version: '1',
        chainId: chainId,
        nonce: nonce,
      });
      return message
    },

    getMessageBody: ({ message }) => {
      return message.prepareMessage();
    },

    verify: async ({ message, signature }) => {
      const jsonData = await apis.backendRequest("wallet_signin", {
        "message": message,
        "signature": signature,
      });

      if (jsonData.ok) {
        setAuthStatus("authenticated")
        const newSession = {
          address: jsonData.address,
          token: jsonData.session.token,
          expiresAt: jsonData.session.expires_at,
        }
        sessionInfo.current = newSession
        setSession(sessionInfo.current)
        localStorage.setItem("walletSigninSessionData", JSON.stringify(newSession))
      }
      return jsonData.ok;
    },

    signOut: async () => {
      await logoutSession()
      setAuthStatus("unauthenticated")
    },
  });
  // Rainbow Configs End

  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitAuthenticationProvider
        adapter={authenticationAdapter}
        status={authStatus}
        enabled={true}
      >
        <RainbowKitProvider
          chains={chains}
          theme={lightTheme({
            accentColor: '#201CE0',
            accentColorForeground: 'white',
            borderRadius: 'medium',
            fontStack: 'system',
            overlayBlur: 'small',
          })}>
          {navbar}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/a16zcssreject" element={<Campaign />} />
            <Route path='*' element={<Page404 />} />
          </Routes>
          {page && page !== "404" && <Feedback />}
          {page && page !== "404" && <div className="footer">Built with ❤️ in California</div>}
        </RainbowKitProvider>
      </RainbowKitAuthenticationProvider>
    </WagmiConfig>
  );

}

export default RainbowedApp;
